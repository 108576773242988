// extracted by mini-css-extract-plugin
export var column = "LocationBrochuresDashboardOptions__column__GnG5G";
export var flex = "LocationBrochuresDashboardOptions__flex__SXCtL";
export var flexColumn = "LocationBrochuresDashboardOptions__flexColumn__pBsqD";
export var gap1 = "LocationBrochuresDashboardOptions__gap1__vQwbG";
export var gap2 = "LocationBrochuresDashboardOptions__gap2__OE62v";
export var gap3 = "LocationBrochuresDashboardOptions__gap3__rHRfF";
export var gap4 = "LocationBrochuresDashboardOptions__gap4__I5Vjj";
export var gap5 = "LocationBrochuresDashboardOptions__gap5__eTZr6";
export var popoverBody = "LocationBrochuresDashboardOptions__popoverBody__UDzX6";
export var popoverBodyLink = "LocationBrochuresDashboardOptions__popoverBodyLink__puE6B";
export var popoverBodyLinkIcon = "LocationBrochuresDashboardOptions__popoverBodyLinkIcon__rfSp2";
export var row = "LocationBrochuresDashboardOptions__row__PNISA";